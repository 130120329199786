<template id="SignUp">
    <section :class="sectionClasses">
        <div class="sign-up-grid" :aa-region="formConfig.aaRegion">
            <div class="grid-item">
                <steps-header v-if="!hasSkipExplainers" :hideStepsProp="isPartner" />

                <h1 v-if="dspHeading" class="view-heading">
                    {{ getHeading }}
                </h1>

                <p v-if="getSubHeading" class="sub-heading">
                    {{ getSubHeading }}
                </p>

                <div
                    v-if="fromPartnerConfirmation || inPartnerFlow"
                    class="helper-text-email-address"
                    id="helper-text-email-address">
                    {{ $getLocale('you_can_use_any_email_address') }}
                </div>
                <p v-if="formMessageError" role="alert" class="form-message error">
                    {{ formMessageError }}
                </p>
            </div>

            <form id="sign-up-form" v-on:submit.stop.prevent="handleSubmit" novalidate>
                <FormFields
                    :formFieldList="formFieldList"
                    :class="classList"
                    @submitValidate="handleSubmit"
                    :fieldErrors="fieldErrors"
                    @updateFormData="updateFormData"
                    @validateField="validateField"
                    @validateForm="validateForm"
                    @trackCheckBox="trackCheckBox"
                    @showHidePasswordStatus="handleShowHidePasswordStatus"></FormFields>

                <div
                    class="disclaimer"
                    v-if="
                        !formFieldList['disclaimer'] &&
                        getFieldLabel('disclaimer') &&
                        !this.overrideRequiredAgreementCheckboxEnabled()
                    "
                    v-html="getFieldLabel('disclaimer')"></div>

                <UserAcknowledgement component="signup"></UserAcknowledgement>

                <FormButton
                    :buttonConfig="buttonConfig"
                    :buttonState="submitButtonState"
                    class="button"
                    @click.native="handleSubmit"></FormButton>
            </form>
        </div>
        <BackToRendezvousOptions :separator="false" v-if="!isDomestic"></BackToRendezvousOptions>
    </section>
</template>

<script>
    import BackToRendezvousOptions from 'aa/vue/components/BackToRendezvousOptions';
    import ButtonCta from 'atoms/ButtonCta';
    import CheckboxElm from 'atoms/CheckboxElm';
    import CustomSelect from 'atoms/CustomSelect';
    import Domain from 'helpers/Domain';
    import FormButton from 'atoms/FormButton';
    import FormFields from 'atoms/FormFields';
    import FormState from 'aa/vue/plugins/FormState';
    import InputElm from 'atoms/InputElm';
    import LabelInput from 'atoms/LabelInput';
    import LabelInputBirthDate from 'atoms/LabelInputBirthDate';
    import MVPDBindMixin from 'aa/vue/plugins/MVPDBindMixin';
    import StepsHeader from 'aa/vue/components/StepsHeader';
    import trackBindEntitlementMixin from 'aa/vue/plugins/trackBindEntitlementMixin';
    import PartnerService from 'services/PartnerService';
    import UrlHelper from 'helpers/UrlHelper';
    import isEqual from 'lodash/isEqual';
    import { MFE_USERPROFILES_ENABLED } from 'aa/helpers/featureConstants';
    import {
        AA_XHR_BASE_PATH,
        HOME_PATH,
        SHOW_PICKER_PATH,
        SIGNUP_URL,
    } from 'aa/vue/constants/aaRoutes';
    import { SignUpConfig } from 'aa/vue/FormConfig';
    import { getMVPDAuthZObj } from 'helpers/mvpdStorage';
    import { mapState, mapActions, mapGetters } from 'vuex';
    import {
        tracking,
        ACTION_NAMES,
        APP_LOG_TYPE,
        PAGE_NAMES,
        PAGE_TYPES,
    } from 'services/Tracking';
    import PartnerMixin from './partner/PartnerMixin';
    import UserAcknowledgement from 'aa/vue/components/consents/UserAcknowledgement';
    import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
    import commonMixins from 'aa/vue/plugins/account/commonMixins';
    import { FLOW_TYPE } from 'models/FlowModel';
    import {
        AGE_GATE,
        MULTI_SUB_PLAN_PICKER_ENABLED,
        SKIP_EXPLAINER_STEPS,
    } from 'aa/helpers/featureConstants';
    import moment from 'moment';
    import { toInteger } from 'lodash';
    import {
        USER_REG_TYPE,
        ACTION_NAMES as AA_ACTION_NAMES,
        PAGE_TYPES as AA_PAGE_TYPES,
    } from 'aa/vue/constants/aaTrackingConstants';

    export default {
        props: {
            errorCallBack: {
                type: Function,
            },
            successCallback: {
                type: Function,
            },
            fromConfirmation: {
                type: Boolean,
                default: () => false,
            },
            fromPartnerConfirmation: {
                type: Boolean,
                default: () => false,
            },
            partnerRecaptchaAction: {
                type: String,
                required: false,
                default: '',
            },
        },
        mixins: [FormState, MVPDBindMixin, PartnerMixin, commonMixins, trackBindEntitlementMixin],
        watch: {
            authSuiteTokens(newValue, oldValue) {
                if (this.fromConfirmation) {
                    if (this.tokenStatus === 'valid' && !isEqual(newValue, oldValue)) {
                        this.registerWithApi(this.authSuiteTokenErrorCallBack);
                    }
                }
            },
            pageAttr() {
                this.setFields();
            },
        },
        data: function () {
            return {
                heading: 'Sign Up',
                form: {},
                tnc: null,
                errors: [],
                buttonConfig: Object.assign(SignUpConfig.buttonConfig, {
                    event: this.handleSubmit,
                }),
                formMessageError: '',
                formName: 'aa_sign_up',
                marketingCheckBox: false,
                tcConsentStatus: null,
                mktConsentStatus: null,
                isDomestic: Domain.isDomestic(),
                inPartnerFlow: this.$store.getters['partner/inPartnerFlow'],
                marketingConsent: null,
                passIsVisible: 0,
                recaptchaAction: 'FORM_SIGN_UP',
            };
        },
        mounted() {
            if (this.partnerRecaptchaAction !== '') {
                this.recaptchaAction = this.partnerRecaptchaAction;
            }

            if (Domain.isInternational() && this.$store.getters['flow/type'] === FLOW_TYPE.GIFT) {
                this.openNotification(
                    new NotificationBarModel({
                        message: this.$getLocale(
                            'to_redeem_a_gift_card_you_need_an_account_create_one_or_sign_in',
                        ),
                        autoClose: false,
                    }),
                );
            }
            const element = this.$el.querySelector('input[tabindex="1"]');
            if (element) {
                element.focus();
            }
        },
        methods: {
            ...mapActions('authSuite', {
                checkAccessStatus: 'checkAccessStatus',
                registerWithApi: 'registerWithApi',
            }),
            ...mapActions('partner', {
                bindWithPartner: 'bindWithPartner',
            }),
            isUnderAge() {
                // Birthday is stored without slashes or spaces, so we need to split it up
                const resp = RegExp('([0-9]{2})([0-9]{2})([0-9]{4})').exec(this.formData.birthday);
                if (!resp || resp.length < 4) return false;
                const age_limit = this.$store.state.serverData?.ageGateMinimumAge;
                // Preferred formt for moment() is Y-M-D
                const diff = moment().diff(resp[3] + '-' + resp[1] + '-' + resp[2], 'years', true);
                return diff < toInteger(age_limit);
            },
            handleShowHidePasswordStatus(payload) {
                this.passIsVisible = Number(payload);
                tracking.trackAction(
                    this.passIsVisible
                        ? ACTION_NAMES.SHOWHIDE_PASSWORD_SHOW
                        : ACTION_NAMES.SHOWHIDE_PASSWORD_HIDE,
                    {
                        passIsVisible: this.passIsVisible.toString(),
                    },
                );
            },
            inCurrentCountry(countryList) {
                return countryList.indexOf(this.marketingCheckBox.currentCountry) > -1;
            },
            mvpdBindSuccessCallback() {
                if (this.$store.getters['flow/isRendezvous']) {
                    window.location.assign(this.$store.getters['flow/destinationUrl']);
                } else if (this.featureIsActive(MFE_USERPROFILES_ENABLED)) {
                    window.location.assign(SHOW_PICKER_PATH);
                } else {
                    window.location.assign(HOME_PATH);
                }
            },
            trackCheckBox(value, e) {
                this.marketingConsent = value && Domain.isDomestic() ? '1' : '0';
                if (Domain.isInternational()) {
                    let trackingType;
                    if (e === 'optIn') {
                        this.mktConsentStatus = this.getMktConsentStatus(value);
                        trackingType = value
                            ? ACTION_NAMES.MARKETING_CHECKBOX_SELECT
                            : ACTION_NAMES.MARKETING_CHECKBOX_DESELECT;
                    } else if (e === 'requiredAgreement') {
                        trackingType = value
                            ? ACTION_NAMES.TC_CHECKBOX_SELECT
                            : ACTION_NAMES.TC_CHECKBOX_DESELECT;
                    }
                    tracking.trackAction(trackingType, {
                        pageType: PAGE_TYPES.SIGNUP,
                        pageName: SIGNUP_URL,
                        pageUrl: window.location.href,
                    });
                }
            },

            trackRegistration() {
                let userRegType = this.$store.getters['flow/userRegType'];
                const trackingPayload = {};
                if (this.fromConfirmation) {
                    Object.assign(trackingPayload, {
                        mvpdPartnerName: this.mvpdPartnerName,
                        mvpdPartnerId: this.mvpdPartnerId,
                        authPartnerId: this.mvpdPartnerName,
                    });
                }

                if (this.mvpdEventBound) {
                    userRegType = USER_REG_TYPE.MVPD;
                }

                tracking.trackRegistration(
                    this.user,
                    userRegType,
                    this.$store.getters['flow/ottActivationPartner'],
                    this.$store.getters['flow/code'],
                    this.getMarketCheckBox(),
                    this.marketingConsent,
                    this.passIsVisible.toString(),
                    trackingPayload,
                );
            },

            overrideOptIn() {
                if (this.inCurrentCountry(this.marketingCheckBox.marketing_optin_countries_1)) {
                    return this.marketingCheckBox.marketing_optin_copy_1;
                } else if (
                    this.inCurrentCountry(this.marketingCheckBox.marketing_optin_countries_2)
                ) {
                    return this.marketingCheckBox.marketing_optin_copy_2;
                } else {
                    return false;
                }
            },
            overrideOptInCheckboxState() {
                if (this.inCurrentCountry(this.marketingCheckBox.marketing_optin_checked)) {
                    return true;
                } else {
                    return false;
                }
            },
            getMarketCheckBox() {
                if (this.marketingCheckBox) {
                    return {
                        tcConsentStatus: this.tcConsentStatus,
                        mktConsentStatus: this.mktConsentStatus,
                    };
                }
                return false;
            },
            getTcConsentStatus() {
                if (this.marketingCheckBox) {
                    return this.overrideRequiredAgreementCheckboxEnabled() ? 1 : 2;
                }
            },
            getMktConsentStatus(value) {
                if (this.marketingCheckBox) {
                    if (this.overrideOptInCheckboxState()) {
                        return value ? 1 : 2;
                    } else {
                        return value ? 3 : 4;
                    }
                }
            },
            overrideRequiredAgreementCheckboxEnabled() {
                if (
                    this.marketingCheckBox &&
                    this.inCurrentCountry(this.marketingCheckBox.terms_checkbox_enabled)
                ) {
                    return true;
                } else {
                    return false;
                }
            },
            overrideRequiredAgreement() {
                return this.marketingCheckBox.terms_copy;
            },
            setFields() {
                const form = {
                    ...this.$root.serverData.signUpForm,
                };
                const pageAttributes = this.$store.getters['pageAttributes/getAllPageAttributes'];
                this.marketingCheckBox = this.$root.serverData.marketingCheckBoxAttributes;
                if (this.marketingCheckBox) {
                    form.optIn.checked = this.overrideOptInCheckboxState();
                    pageAttributes.aa_sign_up_requiredagreement =
                        this.overrideRequiredAgreementCheckboxEnabled();
                    this.mktConsentStatus = this.getMktConsentStatus(form.optIn.checked ? 1 : null);
                    this.tcConsentStatus = this.getTcConsentStatus();
                }
                this.marketingConsent = form?.optIn?.checked && Domain.isDomestic() ? '1' : '0';
                if (form.requiredAgreement && !pageAttributes.aa_sign_up_requiredagreement) {
                    delete form.requiredAgreement;
                }
                if (this.$store.getters['flow/isEdu'] && this.$store.getters['user/eduProfile']) {
                    let eduFormValues = this.$store.getters['user/eduProfile'];
                    form.fullName.value = eduFormValues.fullName;
                    form.email = Object.assign(form.email, {
                        value: eduFormValues.email,
                        readonly: 'readonly',
                    });
                    form.birthday.value = eduFormValues.birthdate;
                }

                // START TEST PLOCTOPLUS-2873: email confirmation field ab test
                if (!this.displayConfirmationEmailField) {
                    delete form.confirmEmail;
                }
                // END TEST PLOCTOPLUS-2873: email confirmation field ab test

                this.setFieldConfigs(form);
                this.setValidationService();
            },
            getFieldLabel: function (fieldName) {
                // TODO: move to mixin SubFlowForm
                if (this.marketingCheckBox) {
                    if (fieldName === 'optIn') {
                        let overrideOptIn = this.overrideOptIn();
                        if (overrideOptIn) {
                            return overrideOptIn;
                        }
                    }
                    if (fieldName === 'requiredAgreement' || fieldName === 'disclaimer') {
                        let overrideRequiredAgreement = this.overrideRequiredAgreement();

                        if (overrideRequiredAgreement) {
                            return overrideRequiredAgreement;
                        }
                    }
                }
                if (this.pageAttr[this.formName + '_' + fieldName.toLowerCase()]) {
                    return this.pageAttr[this.formName + '_' + fieldName.toLowerCase()];
                }
            },
            authSuiteTokenErrorCallBack: function () {
                this.checkAccessStatus();
            },

            handleSubmit(e) {
                // We don't need to even test the form if the button is still disabled.
                if (this.formIsProcessing) {
                    return false;
                }
                if (this.hasAgeGate && this.isUnderAge()) {
                    this.$router.push({ name: 'AGE_GATE' });
                    return false;
                }
                if (this.formIsValid) {
                    this.handleValidForm();
                } else {
                    this.validateForm(
                        {
                            on: 'submit',
                        },
                        e,
                    );
                }
            },

            handleValidForm: async function () {
                if (this.buttonConfig.styleClass.indexOf('disabled') >= 0) {
                    return;
                }
                this.buttonConfig.styleClass.push('disabled');
                const http = this.$store.$http;
                const httpData = Object.assign({}, this.formData);
                /* Special Repair to Gender Value */
                /* Remove when CustomSelect emits input value */
                if (httpData.gender) {
                    httpData.gender = httpData.gender.split(' ').join('_');
                }
                httpData.tk_trp = this.$store.state.token;

                if (this.userConsent) {
                    httpData.flowType = this.$store.getters['flow/type'];
                }

                const context = this;
                if (Domain.isDomestic()) {
                    try {
                        await this.unbindMVPDIfNeeded();
                    } catch (e) {
                        return;
                    }
                }

                if (
                    this.hasOwnProperty('recaptchaAction') &&
                    this.recaptchaFromActionEnabled(this.recaptchaAction)
                ) {
                    httpData.recaptchaAction = this.recaptchaAction;
                    if (this.recaptchaAction === 'FORM_PARTNER_SIGN_UP') {
                        httpData.isPartner = true;
                    }
                }

                http.doPost(`${AA_XHR_BASE_PATH}register/`, httpData)
                    .then(async (res) => {
                        if (res.success) {
                            this.$store.commit('user/parseUserData', res.data.user);
                            this.trackRegistration();

                            context.formMessageError = '';

                            let flowType = this.$store.getters['flow/type'];

                            if (Domain.isDomestic() && getMVPDAuthZObj()) {
                                try {
                                    await this.bindMVPD();
                                } catch (e) {
                                    return;
                                }
                            }

                            if (this.fromConfirmation) {
                                this.registerWithApi({
                                    errorCallBack: this.authSuiteTokenErrorCallBack,
                                    successCallback: this.mvpdBindSuccessCallback,
                                    trackBindSuccess: this.trackRegisterWithMVPD,
                                });
                            } else if (this.fromPartnerConfirmation || this.inPartnerFlow) {
                                this.bindWithPartner().then((response) => {
                                    if (response?.success === true) {
                                        this.trackPartnerBindingSuccess(
                                            AA_PAGE_TYPES.SIGNUP,
                                            response.user,
                                        );
                                    } else {
                                        this.trackPartnerBindingFail(response);
                                        this.showPartnerError(
                                            response?.errorCode,
                                            'BINDING_ERROR',
                                            true,
                                        );
                                    }
                                });
                            } else if (flowType === 'f-gift') {
                                let token;
                                if (
                                    Domain.isInternational() &&
                                    (token = window.sessionStorage.getItem('gift_token'))
                                ) {
                                    let currentPlanCode = context.currentPlan.code;

                                    let httpData = {
                                        token: token,
                                        product_code: currentPlanCode,
                                        tk_trp: this.$store.state.token,
                                    };

                                    context.$store
                                        .dispatch('payment/processGiftPurchase', httpData)
                                        .then((data) => {
                                            window.location.href = Domain.isDomestic()
                                                ? UrlHelper.getHomePageURL()
                                                : '/subscription/payment/confirmation/?orderid=' +
                                                  data.order_id;
                                        })
                                        .catch((err) => {
                                            this.notifyGiftPurchaseError({ name: 'GIFT' });
                                        });
                                } else {
                                    context.$router.push(this.getNextStep.path);
                                }
                            } else if (
                                this.$route.query.redirectUrl &&
                                this.$route.query.redirectUrl.includes('/account/direct/')
                            ) {
                                window.location.href = this.$route.query.redirectUrl;
                            } else {
                                if (
                                    typeof context.$options.propsData.successCallback !==
                                    'undefined'
                                ) {
                                    context.$options.propsData.successCallback(context);
                                } else if (context.$store.getters['flow/skipPayment']) {
                                    window.location.href =
                                        context.$store.getters['flow/destinationUrl'];
                                } else if (Domain.isDomestic() && flowType === FLOW_TYPE.EDU) {
                                    context.$router.push({
                                        name: this.$store.getters['payment/paymentRoute'],
                                    });
                                } else {
                                    // START TEST CBSCOMSUB-8800: Purchase Funnel Ordering
                                    if (this.$store.getters['variant/purchaseFunnelOrdering']) {
                                        context.$router.push('/account/signup/pickplan/');
                                    }
                                    // END TEST CBSCOMSUB-8800: Purchase Funnel Ordering
                                    context.$router.push(this.$store.getters['flow/getNextStep']);
                                }
                            }
                        } else {
                            tracking.trackAction(ACTION_NAMES.APP_LOG, {
                                appLogText: res.message || 'No message provided',
                                appLogType: APP_LOG_TYPE.SIGN_UP_ERROR,
                                appLogCode: 'No code provided',
                                passIsVisible: this.passIsVisible.toString(),
                                mvpdPartnerName: this.mvpdPartnerName,
                                mvpdPartnerId: this.mvpdPartnerId,
                                authPartnerId:
                                    this.mvpdPartnerName && this.mvpdPartnerName !== 'unknown'
                                        ? this.mvpdPartnerName
                                        : this.authPartnerId,
                            });

                            this.displayFormErrors(res);
                        }
                    })
                    .catch((err) => {
                        this.displayFormErrors();
                    });
            },
            textInputErrorCSS: (key) => {
                return 'text-input-error qt-err-' + key;
            },

            trackRegisterWithMVPD(user) {
                let purchaseTrackingParams = {
                    userRegType: USER_REG_TYPE.MVPD,
                    mvpdPartnerName: this.mvpdPartnerName,
                    mvpdPartnerId: this.mvpdPartnerId,
                    authPartnerId: this.mvpdPartnerName,
                    purchaseOrderComplete: '1',
                    purchaseQuantity: '1',
                    purchaseEventOrderComplete: '1',
                    purchaseProduct: user.svod.user_package.product_code,
                    pickPlanType: user.svod.user_package.product_name,
                    pickPlanSku: user.svod.user_package.product_code,
                    purchaseProductName: user.svod.user_package.product_name,
                    purchasePrice: 'na',
                    vendorCode: user.svod.user_package.vendor_code,
                };

                tracking.trackAction(
                    AA_ACTION_NAMES.MVPD_ACCOUNT_BINDING_SUCCESS,
                    purchaseTrackingParams,
                );
            },

            displayFormErrors(response) {
                this.buttonConfig.styleClass = this.buttonConfig.styleClass.filter(
                    (cssClass) => cssClass !== 'disabled',
                );

                if (response) {
                    this.formMessageError =
                        typeof response.message !== 'undefined' &&
                        response.message !== '' &&
                        response.message !== 'no error provided' &&
                        response.message !== 'VALIDATION_FAILED'
                            ? response.message
                            : this.$getLocale('account_creation_error', {});

                    if (typeof this.$options.propsData.errorCallback !== 'undefined') {
                        this.$options.propsData.errorCallback();
                    } else if (response.errors) {
                        this.setFieldErrors(response.errors);
                        this.formMessageError = '';
                    }
                } else {
                    // if no response is passed, show generic error
                    this.formMessageError = this.$getLocale('account_creation_error', {});
                }
            },
        },

        computed: {
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),
            ...mapState({
                hasPromoCode: (state) => {
                    return state.promo !== null;
                },
            }),
            ...mapGetters({
                recaptchaFromActionEnabled: 'featureFlags/recaptchaFromActionEnabled',
            }),
            ...mapGetters(['bundleShowtime', 'featureIsActive']),
            // START TEST PLOCTOPLUS-2873: email confirmation field ab test
            ...mapGetters({
                displayConfirmationEmailField: 'variant/displayConfirmationEmailField',
            }),
            // END TEST PLOCTOPLUS-2873: email confirmation field ab test
            ...mapState(['user']),
            ...mapState('flow', {
                flow: (state) => state.config,
                userConsent: (state) => state.userConsent,
            }),
            ...mapState('authSuite', {
                authSuiteTokens: (state) => state.tokens,
                tokenStatus: (state) => state.tokenStatus,
                mvpdPartnerId: (state) => state.selectedProviderCode,
                mvpdPartnerName: (state) => state.selectedProviderName,
                mvpdEventBound: (state) => state.mvpdLoginEventBound,
            }),
            hasSkipExplainers() {
                return this.featureIsActive(SKIP_EXPLAINER_STEPS);
            },
            hasAgeGate() {
                return this.featureIsActive(AGE_GATE);
            },
            sectionClasses() {
                return {
                    'padded-container': true,
                };
            },
            classList() {
                return ['form-wrapper', Domain.isInternational() ? 'form-wrapper--full' : ''];
            },
            dspHeading() {
                return this.hasSkipExplainers && this.getHeading;
            },
            getHeading() {
                return this.pageAttr.aa_page_header;
            },
            getSubHeading() {
                if (this.hasSkipExplainers) {
                    return this.pageAttr.aa_page_sub_header;
                } else {
                    return this.pageAttr.aa_sign_up_sub_heading;
                }
            },

            isInError() {
                return (key) => {
                    if (this.errors.length === 0) {
                        return false;
                    }
                    const result = this.errors.filter((err) => typeof err[key] !== 'undefined');
                    return result.length !== 0;
                };
            },
            formConfig() {
                return SignUpConfig;
            },
            plan() {
                if (this.$store.getters.featureIsActive(MULTI_SUB_PLAN_PICKER_ENABLED)) {
                    return this.$store.getters['plan/getSelectedPlan'];
                } else {
                    return this.$store.getters['plan/getCurrentPlan'];
                }
            },
            getNextStep: function () {
                return this.$store.getters['flow/getNextStep'];
            },
            isPartner() {
                // TODO: Check if any partner flow is in use
                return PartnerService.isPartnerFlow(this.$store.state.partner.partnerCSPValue);
            },
        },
        filters: {
            valuesFromObj: (objStr) => {
                return Object.values(objStr).toString();
            },
        },
        components: {
            UserAcknowledgement,
            StepsHeader,
            ButtonCta,
            InputElm,
            FormFields,
            FormButton,
            LabelInput,
            CustomSelect,
            CheckboxElm,
            LabelInputBirthDate,
            BackToRendezvousOptions,
        },

        // Lifecycle Hooks
        created() {
            this.setFields();
        },
        updated() {
            const button = document.querySelector('.button__close');
            if (button) {
                button.focus();
            }
        },
    };
</script>
