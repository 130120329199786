import { getLocale } from 'services/LocalizationService';
import { ACTION_NAMES, APP_LOG_TYPE, tracking } from 'services/Tracking';
import { ACTION_NAMES as AA_ACTION_NAMES, PAGE_TYPES } from 'aa/vue/constants/aaTrackingConstants';
import { capitalize } from 'aa/vue/components/account/helpers';
import Product from 'aa/vue/models/Product';

const errors = {
    ACTIVE_USER_PACKAGE_EXISTS: 'active_user_package_exists',
    PARTNER_SUBSCRIPTION_ALREADY_BOUND: 'partner_subscription_already_bound',
    PARTNER_SUBSCRIPTION_STATUS_INVALID: 'partner_subscription_status_invalid',
    PARTNER_SUBSCRIPTION_UNAUTHORIZED: 'partner_subscription_status_invalid',
    PRODUCT_NOT_FOUND: 'product_not_found',
    USER_CONNECTION_ALREADY_EXISTS: 'user_connection_already_exists',
};

export default {
    computed: {
        partnerPlanTypes() {
            return this.$store?.state?.serverData?.partnerPlanTypes;
        },
        planName() {
            const packageInfo = this.getPackageInfo || this.packageInfo;
            const packagePlanTier = packageInfo?.plan_tier?.toLowerCase();
            const partnerPlanTypes = this.partnerPlanTypes?.planTypes;

            const isMultiplePlanTypes = partnerPlanTypes?.length > 1;
            const planTypeKeyTranslations = {
                mobile: 'basic',
                ads: 'basic_with_ads',
            };
            const planType =
                planTypeKeyTranslations[packagePlanTier] ??
                partnerPlanTypes?.find((plan) => plan === packagePlanTier);
            const localizedPlanType = this.$getLocale(planType ?? Product.TIER_STANDARD);
            const capitalizedPlanType = capitalize(localizedPlanType);

            return isMultiplePlanTypes ? `Paramount+ ${capitalizedPlanType}` : 'Paramount+';
        },
    },
    methods: {
        showPartnerError: function (
            errorCode,
            errorType = 'UNSPECIFIED_ERROR',
            skipTracking = false,
        ) {
            let heading = 'an_error_has_occurred_please_try_again_at_a_later_time';
            let subHeading =
                'we_will_sign_you_out_please_try_to_sign_in_again_if_you_continue_to_encounter_this_issue_please_contact_your_partner_directly';
            const partnerAuthAttributes = this.$store.state.partner.partnerAuthAttributes;
            const wildcards = {
                partner: partnerAuthAttributes.csp_display_name,
            };

            if (errorCode && errors[errorCode]) {
                const prefix = errors[errorCode];
                heading = `${prefix}_title`;
                subHeading = `${prefix}_description`;
            }

            this.$store.commit('setUserMessage', {
                heading: getLocale(heading, wildcards),
                subHeading: getLocale(subHeading, wildcards),
                primaryButton: {
                    label: getLocale('close'),
                    link: this.$store.state.serverData.regionBaseUrl,
                },
            });

            if (skipTracking) {
                return;
            }

            tracking.trackAction(ACTION_NAMES.APP_LOG, {
                pageType: PAGE_TYPES.BIND_FAILURE,
                appLogText: getLocale(heading, wildcards),
                appLogType: errorType,
                appLogCode:
                    errorCode === true
                        ? `No specific code - ${partnerAuthAttributes.csp_display_name}`
                        : errorCode,
            });
        },

        trackPartnerBindingSuccess(pageType, user) {
            const trackingParams = {
                link_name: AA_ACTION_NAMES.BIND_SUCCESS,
                pageType: pageType,
                pageUrl: window.location.href,
                purchaseEventOrderComplete: '1',
                purchaseProduct: user?.svod?.user_package?.product_code,
                purchaseQuantity: '1',
                authPartnerId: window.utag_data?.authPartnerId,
                packageSource: user?.tags?.packageSource,
                purchaseProductName: user?.svod?.user_package?.product_name,
                purchasePaymentMethod: this.$store.state.payment?.paymentMethod,
                purchaseOrderId: window?.utag_data?.purchaseOrderId,
                productOfferPeriod: window?.utag_data?.purchaseOfferPeriod,
                productPricingPlan: user?.svod?.user_package?.plan_type,
                packageStatus: user?.tags?.packageStatus,
            };
            tracking.trackAction(AA_ACTION_NAMES.BIND_SUCCESS, trackingParams);
        },

        trackPartnerBindingFail(res) {
            const trackingParams = {
                link_name: ACTION_NAMES.APP_LOG,
                pageType: PAGE_TYPES.BIND_FAILURE,
                pageUrl: window.location.href,
                authPartnerId: window?.utag_data?.authPartnerId,
                packageSource: this.user?.tags?.packageSource,
                appLogText: res?.message,
                appLogType: APP_LOG_TYPE.APP_LOG_ERROR_TYPE,
                appLogCode: res?.errorCode,
                packageStatus: this.user?.tags?.packageStatus,
            };
            tracking.trackAction(ACTION_NAMES.APP_LOG, trackingParams);
        },
    },
};
